$(function() {
    var owl = $('.gallery').owlCarousel({
        loop: true,
        nav: true,
        dots: true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:3
            },
            1260:{
                items:4
            }
        }
    });
    
    /* animate filter */
    var owlAnimateFilter = function(even) {
        $(this)
        .addClass('__loading')
        .delay(60 * $(this).parent().index())
        .queue(function() {
            $(this).dequeue().removeClass('__loading');
        })
    }

    $('.gallery-cat-link').on('click', function(e) {
        e.preventDefault();
        var filter_data = $(this).data('filter');

        /* return if current */
        if($(this).hasClass('active')) return;

        /* active current */
        $(this).addClass('active').siblings().removeClass('active');

        /* Filter */
        owl.owlFilter(filter_data, function(_owl) { 
            $(_owl).find('.item').each(owlAnimateFilter); 
        });
    });


    // Custom Navigation Events
    $(".next").click(function(){
        owl.trigger('next.owl.carousel');
    })
    $(".prev").click(function(){
        owl.trigger('prev.owl.carousel');
    })

    $(".gallery-thumbs").fancybox({
        padding: 0,
        opacity: true,
        cyclic: true,
        aspectRatio: true,
        scrolling: 'no',
        nextEffect: 'fade',
        prevEffect: 'fade',
        href: this.href,
        helpers: {
            title: {
                type: 'inside'
            }
        },
        beforeShow: function(){$.fn.fullpage.setAllowScrolling(false);},
        afterClose: function(){$.fn.fullpage.setAllowScrolling(true);}
    });

    $('.gallery-video').fancybox({
        padding: 0,
        opacity: true,
        cyclic: true,
        aspectRatio: true,
        scrolling: 'no',
        openEffect: 'fade',
        closeEffect: 'fade',
        nextEffect: 'fade',
        prevEffect: 'fade',
        type: 'iframe',
        swf: { wmode: 'transparent', allowfullscreen: 'true' },
        beforeLoad: function () {
            var url = $(this.element).attr("id");
            this.href = this.href.replace(new RegExp("watch\\?v=", "i"), 'embed/')+'?autoplay=1&amp;rel=0&amp;showinfo=0';
        },
        afterLoad: function (a,b) {
            $(a.outer).addClass('video');
        }
    });
    $(document).on('click', '.gallery-video', function(e) {
        /*$.each('.gallery-video', function(){
            $(this).fancybox({
                padding: 0,
                opacity: true,
                cyclic: true,
                aspectRatio: true,
                scrolling: 'no',
                openEffect: 'fade',
                closeEffect: 'fade',
                nextEffect: 'fade',
                prevEffect: 'fade',
                type: 'iframe',
                href: this.href.replace(new RegExp("watch\\?v=", "i"), 'embed/')+'?autoplay=1&amp;rel=0&amp;showinfo=0',
                swf: { wmode: 'transparent', allowfullscreen: 'true' }
            });
        });*/ 
        e.preventDefault();
    });

});