//navbar toggle
$(function(){
	$('.navbar-toggle').click(function(){
		if($('body').hasClass('show-nav')){
			$('body').removeClass('show-nav').addClass('hide-nav');
			$(this).attr('aria-expanded', false);
			setTimeout(function(){
				$('body').removeClass('hide-nav');
			},500);
		}else{
			$('body').removeClass('hide-nav').addClass('show-nav');
			$(this).attr('aria-expanded', true);
		}
		return false;
	});
});

//slider onLoad
$( document ).ready(function() {
	//slider init
    $(".main-slider").owlCarousel({
      items: 1,
      loop: false,
      nav: false,
      dots: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      lazyLoad: true,
      video:true,
      mouseDrag:false,
      touchDrag:false,
      pullDrag:false,
      freeDrag:false
    });

	//slider after init
	$(".main-slider").on('initialized.owl.carousel', function(event) {
		$('.owl-item.active .owl-slide').addClass("content-loading");
		$('.owl-item.active .owl-slide').waitForImages({
		    waitForAll: true,
		    finished: function() {
				$('.owl-item').css("opacity", 1);
				$('.owl-item.active .owl-slide').removeClass("content-loading");
				$('.owl-item.active .owl-slide').addClass("fadeIn");
		    }
		});
	});
});


var maxWidth = parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1))+1;

//slider text animation on autoscroll
function sliderText() {
	var maxWidth = parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1))+1,
		fading = $('.owl-slide__text-wrapper .container');
		
	$.each(fading, function(){
		var fadeStart = 0,
			fadeUntil = 500;
		if($(window).width() >= maxWidth) {
		    var offset = $(document).scrollTop(),
		    	opacity = 0;

		    if (offset <= fadeStart){
		        opacity = 1;
		    } else if (offset <= fadeUntil){
		        opacity = 1-offset/fadeUntil;
		    }
		    $(this).css({
		    	'opacity':opacity
		    });
		} else {
		    $(this).css({
		    	'opacity':1
		    });
		}
	});
}

$(window).bind('scroll', function(){
    sliderText();
});

function scrollBottomOpacity() {
	var maxWidth = parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1))+1,
		fading = $('.scroll-bottom');
	$.each(fading, function(){
		var fadeStart = $(this).parents('.section').offset().top,
			fadeUntil = $(this).parents('.section').offset().top + $(this).parents('.section').outerHeight(true)*0.6;
		if($(window).width() >= maxWidth && $(document).scrollTop() >= fadeStart) {
		    var offset = $(document).scrollTop(),
		    	opacity = 0;

		    if (offset <= fadeStart){
		        opacity = 1;
		    } else if (offset <= fadeUntil){
		        opacity = (1-(offset-fadeStart)/(fadeUntil-fadeStart) < 0 ? 0 : 1-(offset-fadeStart)/(fadeUntil-fadeStart));
		    }
		    $(this).css({
		    	'opacity':opacity
		    });
		} else {
		    $(this).css({
		    	'opacity':1
		    });
		}
	});
}

$(window).bind('scroll', function(){
    scrollBottomOpacity();
});

//scroll bottom btn
$(document).on("click", ".scroll-bottom", function(e){
	e.preventDefault();
	$.fn.fullpage.moveSectionDown();
});

$('#more-info').modal({
  keyboard: true,
  backdrop: false
})

//fullpage init
$(document).ready(function() {
	var maxWidth = parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1))+1;

if($(window).outerWidth(true) >= maxWidth - 40) {
	$('#fullpage').fullpage({
		//Scrolling
		css3: true,
		scrollingSpeed: 900,
		scrollOverflow:true,
		//Design
		controlArrows: true,
		verticalCentered: function(){return ($(window).width() >= maxWidth ? true : false)},
		paddingTop: '80px',
		paddingBottom: '80px',
		scrollBar: true,
		fixedElements: '#header',
		responsiveWidth: parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1)),
		responsiveHeight: 860,

		//Custom selectors
		sectionSelector: '.section',
		slideSelector: '.slide',

		lazyLoading: true,
		onLeave: function(index, nextIndex, direction){
    		$.fn.fullpage.setScrollingSpeed(1000);
	    	/*if(playerReady == true) {
		    	if(nextIndex == 1) {
		    		tv.playVideo();
		    	} else {
		    		tv.pauseVideo();
		    	}
	    	}*/
	    	if(nextIndex == 1) {
	            $('body').removeClass('scrolled');
	    	} else {
	            $('body').addClass('scrolled');
	    	}
	    	var time = 0;
	    	if(index == 1) time = 700;
	    	setTimeout(function(){
		    	if($($(".fp-section")[nextIndex+1]).find('.fp-bg').length > 0) {
		            $(".section--slider ~ * .fp-bg").css('visibility','visible');
		    	} else if($($(".fp-section")[nextIndex]).find('.fp-bg').length > 0) {
		            $(".section--slider ~ * .fp-bg").css('visibility','visible');
		    	} else if($($(".fp-section")[nextIndex-1]).find('.fp-bg').length > 0) {
		            $(".section--slider ~ * .fp-bg").css('visibility','visible');
		    	} else if($($(".fp-section")[nextIndex-2]).find('.fp-bg').length > 0) {
		            $(".section--slider ~ * .fp-bg").css('visibility','visible');
		    	} else {
		            setTimeout(function(){$(".section--slider ~ * .fp-bg").css('visibility','hidden');},350);
		    	}
	    	},time);
	    }
	});
} else {
	$(".section--slider").height($(window).height());
	$.each($('[data-anchor]'), function(){
		$(this).attr('id', $(this).data('anchor'));
		$(this).attr('data-anchor', '');
	});
	console.log($('[data-anchor]').attr('id'));
  $('body').scrollspy({ 
    target: '.navbar',
      offset: $(".navbar").outerHeight(true)
  });
  var navbarLink = $(".navbar .navbar-nav li a.nav-scroll");

  // Set hash on scrollspy fire
  $(window).on('activate.bs.scrollspy', function () {
  	var navbarLinkActive = $(".navbar-nav li.active a.nav-scroll");
    gethash = navbarLinkActive.attr('href').split("#");
    hash = "#"+gethash[1];
	try {
    	history.replaceState(null, null, hash);	
	} catch(err) {}
	$(window).load(function(){
    	var offset = $('.navbar').outerHeight(true);
		var $anchor = hash;
		$('html, body').animate({
			scrollTop: $($anchor).offset().top - offset + 1
		}, 850, 'swing');
	});

  });
    $('a.nav-scroll').bind('click', function(event) {
        if($(this).parents('.navbar-nav').length === 1) {$('.navbar .navbar-toggle').click();}
    	var offset = $('.navbar').outerHeight(true);
        var $anchor = $(this).attr('href');
        $('html, body').stop().animate({
            scrollTop: $($anchor).offset().top - offset + 1
        }, 850, 'swing');
        event.preventDefault();
    });
}

var actualBp = getBootstrapBreakpoint();
$(window).on('resize',function(){
	if((actualBp == "lg" || actualBp =="md") && (getBootstrapBreakpoint() == "sm" || getBootstrapBreakpoint() == "xs")) {
		location.reload();
	}
	if((actualBp == "xs" || actualBp =="sm") && (getBootstrapBreakpoint() == "md" || getBootstrapBreakpoint() == "lg")) {
		location.reload();
	}
});

});

//animations
$( document ).ready(function() {

	//logo
	$.each($(".navbar-brand img"), function(i){
		$(this).attr({
			'data-aos':'fade-in',
			'data-aos-anchor':'body',
			'data-aos-anchor-placement':'top-top',
			'data-aos-duration':800,
			'data-aos-easing':'ease-in-sine',
			'data-aos-offset':-10,
			'data-aos-once':'true',
			'data-aos-delay': 150*i
		});
	});

	//navbar li
	$.each($(".navbar-nav li"), function(i){
		$(this).attr({
			'data-aos':'fade-in',
			'data-aos-anchor':'body',
			'data-aos-anchor-placement':'top-top',
			'data-aos-duration':800,
			'data-aos-easing':'ease-in-sine',
			'data-aos-offset':-10,
			'data-aos-once':'true',
			'data-aos-delay': 150*i
		});
	});

	//slide container items
	$.each($(".owl-slide__text-wrapper .container .row div > *"), function(i){
		$(this).attr({
			'data-aos':'fade-in',
			'data-aos-duration':800,
			'data-aos-easing':'ease-in-sine',
			'data-aos-offset':-10,
			'data-aos-once':'true',
			'data-aos-delay': 300
		});
	});

	//scroll bottom button
	$.each($(".scroll-bottom-wrapper"), function(i){
		$(this).attr({
			'data-aos':'fade-up',
			'data-aos-duration':900,
			'data-aos-easing':'ease',
			'data-aos-once':'true',
			'data-aos-delay': 100
		});
	});

	//intro icons
	$.each($(".introIcons").find(".icon-wrapper").parent(), function(i){
		$(this).attr({
			'data-aos':'zoom-in',
			'data-aos-duration':800,
			'data-aos-easing':'ease-out-back',
			//'data-aos-once':'true',
			'data-aos-delay': 400*(i+1)
		});
	});
	//intro icons
	$.each($(".anotherIcons").find(".icon-wrapper").parent(), function(i){
		$(this).attr({
			'data-aos':'zoom-in',
			'data-aos-duration':800,
			'data-aos-easing':'ease-out-back',
			//'data-aos-once':'true',
			'data-aos-delay': 400*(i+1)
		});
	});

	//intro iconsur-team
	$.each($(".ourTeam").find(".ourTeam__title").parent(), function(i){
		$(this).attr({
			'data-aos':'zoom-in',
			'data-aos-duration':800,
			'data-aos-easing':'ease-out-back',
			//'data-aos-once':'true',
			'data-aos-delay': 400*(i+1)
		});
	});

	// proc my divs
	$.each($("section").has(".fadeUpItem"), function(i){
		$.each($(this).find(".fadeUpItem"), function(i){
			if(i <= 2) {
				$(this).attr({
					'data-aos':'fade-up',
					'data-aos-duration':600+300*i,
					'data-aos-easing':'ease',
					//'data-aos-once':'true',
					'data-aos-delay': 100*i,
				});
			} else {
				$(this).attr({
					'data-aos':'fade-up',
					'data-aos-duration':1000+100*i,
					'data-aos-easing':'ease',
					//'data-aos-once':'true',
					'data-aos-delay': 100*i,
				});
			}
		});
	});

	//animations INIT
	AOS.init({
		offset: 0,
		duration: 600,
		disable: function () {
			var maxWidth = parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1))+1;
			return $(window).width() < maxWidth;
		}
	});
});

$(document).ready(function () {
    $("#send-mail").click(function (e) {
        
        var request = $.ajax({
            url: 'mail.php',
            type: 'POST',
            dataType: 'json',
            data: {
                name: $("input[name='name']").val(),
                phone: $("input[name='phone']").val(),
                email: $("input[name='email']").val(),
                message: $("textarea[name='message']").val(),
                captcha: $("input[name='captcha']").val(),
            }
        });
        request.done(function (response) {
            //alert(response.message);
            $('.contact-infobox').html(response.message).show();
            if($('.contact-infobox .alert-success').length) $('.contact-item form')[0].reset();
			//window.location.href = document.location.protocol +"//"+ document.location.hostname + document.location.pathname+'?contactform=1#kontakt';
    		//location.reload(true);
        })
    });
});


$(document).ready(function () {
	$('html').removeClass('loading');
});