//variables


function getLessVariableValue(variable) {
    var elt = document.createElement('div');
    elt.className = "variable-"+variable;
    elt.style.display= "none";        // ensure element is not visible
    document.body.appendChild(elt);   // elt must be in DOM to compute styles
    var result = window.getComputedStyle(elt, ':before').getPropertyValue('content');
    document.body.removeChild(elt);
    return result;
}

function getBootstrapBreakpoint(){
    var w = $(document).innerWidth();
    return (w < parseInt(getLessVariableValue('sm-min').substring(1, getLessVariableValue('sm-min').length-1))) ? 'xs' : 
    	  ((w < parseInt(getLessVariableValue('md-min').substring(1, getLessVariableValue('md-min').length-1))) ? 'sm' : 
    	  ((w < parseInt(getLessVariableValue('lg-min').substring(1, getLessVariableValue('lg-min').length-1))) ? 'md' :
    	  'lg'));
}